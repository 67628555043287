import { Box, Grid, Divider } from "@mui/material";
import BurnMembershipButton from "./BurnMembershipButton";

const MembershipNft = ({
  membershipNft,
  walletAddress,
  fetchMembershipNft,
  fetchCustomer,
  status,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          component="img"
          sx={{
            textAlign: "center",
            width: 250,
            display: "block",
            margin: "auto",
          }}
          src={membershipNft.metadata.image}
        />
      </Grid>
      <Grid item xs={12}>
        <BurnMembershipButton
          tokenId={membershipNft.token_id}
          walletAddress={walletAddress}
          fetchMembershipNft={fetchMembershipNft}
          fetchCustomer={fetchCustomer}
          status={status}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 1 }} />
      </Grid>
    </>
  );
};

export default MembershipNft;
