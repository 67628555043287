import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Storage } from "aws-amplify";
import {
  Box,
  Button,
  TextField,
  Stack,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

const NftMedalEdit = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      nftName: "",
      description: "",
      issuer: "",
      issueDate: "",
      link: "",
    },
  });
  const [nftImageUrl, setNftImageUrl] = useState();
  const [imageUploadErr, setImageUploadErr] = useState(false);
  const [metadataList, setMetadataList] = useState([]);
  const [metadataObj, setMetadataObj] = useState([]);
  const [metadataId, setMetadataId] = useState();
  const [isLocked, setIsLocked] = useState(false);
  const [selectedMetadataId, setSelectedMetadataId] = useState("");
  const [selectedMetadata, setSelectedMetadata] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getMetadataList = async (data) => {
    const res = await API.graphql({
      query: queries.listBiztechNftMedals,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          limit: 99999999,
        },
      },
    });
    console.log(res.data);
    const metadataList = res.data.listBiztechNftMedals.items;
    const metadataObj = Object.fromEntries(
      metadataList.map((obj) => [obj.id, obj])
    );
    setMetadataList(metadataList);
    setMetadataObj(metadataObj);
    return metadataList;
  };
  const updateMetadata = async (data) => {
    const res = await API.graphql({
      query: mutations.updateBiztechNftMedal,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id: metadataId,
          name: data.nftName,
          description: data.description,
          issuer: data.issuer,
          issueDate: data.issueDate,
          link: data.link,
          isLocked: isLocked,
          imageUrl: nftImageUrl,
        },
      },
    });
    console.log(res.data);
    return res.data.updateBiztechNftMedal.id;
  };

  const onSubmit = async (data) => {
    console.log(data);
    console.log({
      id: metadataId,
      name: data.nftName,
      description: data.description,
      issuer: data.issuer,
      issueDate: data.issueDate,
      link: data.link,
      isLocked: isLocked,
      imageUrl: nftImageUrl,
    });
    if (!nftImageUrl) {
      setImageUploadErr(true);
      return;
    } else {
      setImageUploadErr(false);
    }
    await updateMetadata(data);
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    const fileName = `image/${uuidv4()}.jpg`;
    try {
      await Storage.put(fileName, file, {
        contentType: file.type,
      });
      const imageUrl = await Storage.get(fileName);
      setNftImageUrl(imageUrl.split("?")[0]);
      setImageUploadErr(false);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  useEffect(() => {
    getMetadataList();
  }, []);

  const validationRules = {
    nftName: {
      required: "NFTの名前を入力してください。",
    },
    description: {
      required: "説明文を入力してください。",
    },
    issuer: {
      required: "発行元を入力してください。",
    },
    issueDate: {
      required: "発行日時を入力してください。",
    },
    link: {
      required: "関連ページURLを入力してください。",
    },
  };
  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box sx={{ m: 5 }}>
          <Stack
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            spacing={2}
            // sx={{ width: 350 }}
          >
            <FormControl>
              <InputLabel id="metadata-select-label">NFTを選択</InputLabel>
              <Select
                labelId="metadata-select-label"
                id="metadata-select"
                value={selectedMetadataId}
                onChange={async (event) => {
                  setSelectedMetadataId(event.target.value);
                  if (event.target.value) {
                    setSelectedMetadata(metadataObj[event.target.value]);

                    setMetadataId(metadataObj[event.target.value].id);
                    setNftImageUrl(metadataObj[event.target.value].imageUrl);
                    setValue("nftName", metadataObj[event.target.value].name);
                    setValue(
                      "description",
                      metadataObj[event.target.value].description
                    );
                    setValue("issuer", metadataObj[event.target.value].issuer);
                    setValue(
                      "issueDate",
                      metadataObj[event.target.value].issueDate
                    );
                    setValue("link", metadataObj[event.target.value].link);
                    setIsLocked(metadataObj[event.target.value].isLocked);
                  }
                }}
                autoWidth
                label="NFT選択"
              >
                {metadataList?.map((metadata, index) => (
                  <MenuItem value={metadata.id} key={metadata.id}>
                    {metadata.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedMetadataId && (
              <>
                <Box
                  component="img"
                  sx={{
                    width: 250,
                    verticalAlign: "middle",
                    mb: 2,
                  }}
                  alt="NFTimage"
                  src={nftImageUrl}
                />
                <Box>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{
                      color: "rgba(0, 0, 0, 0.50)",
                      borderColor: "rgba(0, 0, 0, 0.30)",
                      width: 250,
                      height: 50,
                      borderRadius: 1,
                      p: 1,
                      mb: 2,
                    }}
                  >
                    NFT画像のアップロード
                    <input type="file" hidden onChange={uploadFile} />
                  </Button>
                </Box>

                <Controller
                  name="nftName"
                  control={control}
                  rules={validationRules.nftName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="NFTの名前"
                      error={errors.nftName !== undefined}
                      helperText={errors.nftName?.message}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  rules={validationRules.description}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="説明文"
                      error={errors.description !== undefined}
                      helperText={errors.description?.message}
                      multiline
                      maxRows={10}
                      minRows={3}
                    />
                  )}
                />
                <Controller
                  name="issuer"
                  control={control}
                  rules={validationRules.issuer}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="発行元"
                      error={errors.issuer !== undefined}
                      helperText={errors.issuer?.message}
                    />
                  )}
                />
                <Controller
                  name="issueDate"
                  control={control}
                  rules={validationRules.issueDate}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="発行日時"
                      error={errors.issueDate !== undefined}
                      helperText={errors.issueDate?.message}
                    />
                  )}
                />
                <Controller
                  name="link"
                  control={control}
                  rules={validationRules.link}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="関連ページURL"
                      error={errors.link !== undefined}
                      helperText={errors.link?.message}
                    />
                  )}
                />
                <FormControlLabel
                  control={<Checkbox checked={isLocked} />}
                  label="譲渡不可のNFTにする"
                  disabled
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      width: 300,
                      p: 2,
                      border: 2,
                      borderRadius: 8,
                      display: "block",
                      margin: "auto",
                      my: 1,
                      "&:hover": {
                        border: 2,
                      },
                    }}
                  >
                    保存する
                  </Button>
                </Box>
                <Typography sx={{ display: "flex", justifyContent: "center" }}>
                  反映に1日〜数日かかる場合があります
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default NftMedalEdit;
