/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBiztechCustomer = /* GraphQL */ `
  query GetBiztechCustomer($walletAddress: ID!) {
    getBiztechCustomer(walletAddress: $walletAddress) {
      walletAddress
      name
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const listBiztechCustomers = /* GraphQL */ `
  query ListBiztechCustomers(
    $walletAddress: ID
    $filter: ModelBiztechCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBiztechCustomers(
      walletAddress: $walletAddress
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        walletAddress
        name
        email
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBiztechNftMedal = /* GraphQL */ `
  query GetBiztechNftMedal($id: ID!) {
    getBiztechNftMedal(id: $id) {
      id
      name
      description
      issuer
      issueDate
      link
      imageUrl
      isLocked
      createdAt
      updatedAt
    }
  }
`;
export const listBiztechNftMedals = /* GraphQL */ `
  query ListBiztechNftMedals(
    $filter: ModelBiztechNftMedalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBiztechNftMedals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        issuer
        issueDate
        link
        imageUrl
        isLocked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
