/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBiztechCustomer = /* GraphQL */ `
  mutation CreateBiztechCustomer(
    $input: CreateBiztechCustomerInput!
    $condition: ModelBiztechCustomerConditionInput
  ) {
    createBiztechCustomer(input: $input, condition: $condition) {
      walletAddress
      name
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateBiztechCustomer = /* GraphQL */ `
  mutation UpdateBiztechCustomer(
    $input: UpdateBiztechCustomerInput!
    $condition: ModelBiztechCustomerConditionInput
  ) {
    updateBiztechCustomer(input: $input, condition: $condition) {
      walletAddress
      name
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteBiztechCustomer = /* GraphQL */ `
  mutation DeleteBiztechCustomer(
    $input: DeleteBiztechCustomerInput!
    $condition: ModelBiztechCustomerConditionInput
  ) {
    deleteBiztechCustomer(input: $input, condition: $condition) {
      walletAddress
      name
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const createBiztechNftMedal = /* GraphQL */ `
  mutation CreateBiztechNftMedal(
    $input: CreateBiztechNftMedalInput!
    $condition: ModelBiztechNftMedalConditionInput
  ) {
    createBiztechNftMedal(input: $input, condition: $condition) {
      id
      name
      description
      issuer
      issueDate
      link
      imageUrl
      isLocked
      createdAt
      updatedAt
    }
  }
`;
export const updateBiztechNftMedal = /* GraphQL */ `
  mutation UpdateBiztechNftMedal(
    $input: UpdateBiztechNftMedalInput!
    $condition: ModelBiztechNftMedalConditionInput
  ) {
    updateBiztechNftMedal(input: $input, condition: $condition) {
      id
      name
      description
      issuer
      issueDate
      link
      imageUrl
      isLocked
      createdAt
      updatedAt
    }
  }
`;
export const deleteBiztechNftMedal = /* GraphQL */ `
  mutation DeleteBiztechNftMedal(
    $input: DeleteBiztechNftMedalInput!
    $condition: ModelBiztechNftMedalConditionInput
  ) {
    deleteBiztechNftMedal(input: $input, condition: $condition) {
      id
      name
      description
      issuer
      issueDate
      link
      imageUrl
      isLocked
      createdAt
      updatedAt
    }
  }
`;
