import { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";

const BurnMembershipButton = ({
  tokenId,
  walletAddress,
  fetchCustomer,
  fetchMembershipNft,
  status,
}) => {
  const { signer, membershipBurn } = useWeb3Provider();
  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = async () => {
    await API.graphql({
      query: mutations.updateBiztechCustomer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          walletAddress: walletAddress,
          status: "BURN",
        },
      },
    });
    await fetchCustomer();
    await fetchMembershipNft();
  };

  const burnMembershipNft = async () => {
    setIsLoading(true);
    await membershipBurn(tokenId);
    await updateStatus();
    setIsLoading(false);
  };
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: "red",
          borderColor: "red",
          "&:hover": {
            borderColor: "red",
            border: 2,
          },
          width: 190,
          p: 3,
          border: 2,
          borderRadius: 5,
          display: "block",
          margin: "auto",
          my: 1,
          "&:hover": {
            border: 2,
          },
        }}
        onClick={burnMembershipNft}
        disabled={isLoading || !signer || status !== "WITHDRAWAL"}
      >
        {isLoading ? (
          <CircularProgress sx={{ color: "red" }} />
        ) : (
          "NFT会員券をBurnする"
        )}
      </Button>
      {!signer && (
        <Typography
          sx={{
            color: "red",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          TOPページでウォレット接続してください
        </Typography>
      )}
    </>
  );
};

export default BurnMembershipButton;
