import NftMedalEdit from "../components/NftMedalEdit";

const EditMedal = () => {
  return (
    <>
      <NftMedalEdit />
    </>
  );
};

export default EditMedal;
