import React from "react";
import Box from "@mui/material/Box";
import HeaderImage from "../public/biztech.png";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Box
        sx={{
          m: 3,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <Box
              component="img"
              sx={{
                verticalAlign: "middle",
                width: "180px",
              }}
              alt="logo"
              src={HeaderImage}
            />
          </Link>
        </Toolbar>
      </Box>
    </>
  );
};

export default Header;
