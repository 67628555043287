import NftMedal from "../components/NftMedal";

const MintMedal = () => {
  return (
    <>
      <NftMedal />
    </>
  );
};

export default MintMedal;
