import { useAuthenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { Container } from "@mui/material";
import Home from "./pages/Home";
import Detail from "./pages/Detail";
import MintMedal from "./pages/MintMedal";
import BatchMintMedal from "./pages/BatchMintMedal";
import Header from "./components/Header";
import CustomAuthenticator from "./components/CustomAuthenticator";
import EditMedal from "./pages/EditMedal";

function App() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  return (
    <>
      <Container disableGutters maxWidth="false">
        <BrowserRouter basename="/">
          {/* <ScrollToTop /> */}
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {authStatus !== "authenticated" ? (
                    <CustomAuthenticator />
                  ) : (
                    <Home />
                  )}
                </>
              }
            />
            <Route
              path="detail/:walletAddress"
              element={
                <>
                  {authStatus !== "authenticated" ? (
                    <CustomAuthenticator />
                  ) : (
                    <Detail />
                  )}
                </>
              }
            />
            <Route
              path="medal/:walletAddress"
              element={
                <>
                  {authStatus !== "authenticated" ? (
                    <CustomAuthenticator />
                  ) : (
                    <MintMedal />
                  )}
                </>
              }
            />
            <Route
              path="metadata/edit"
              element={
                <>
                  {authStatus !== "authenticated" ? (
                    <CustomAuthenticator />
                  ) : (
                    <EditMedal />
                  )}
                </>
              }
            />
            <Route
              path="batch/"
              element={
                <>
                  {authStatus !== "authenticated" ? (
                    <CustomAuthenticator />
                  ) : (
                    <BatchMintMedal />
                  )}
                </>
              }
            />
          </Routes>
          {/* <Footer /> */}
        </BrowserRouter>
      </Container>
    </>
  );
}

export default App;
