import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";

const ConnectButton = () => {
  const { connect } = useWeb3Provider();
  const connectHandler = async () => {
    await connect();
  };
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: 180,
          p: 1,
          border: 2,
          borderRadius: 5,
          "&:hover": {
            border: 2,
          },
        }}
        onClick={connectHandler}
      >
        接続する
      </Button>
    </>
  );
};

export default ConnectButton;
