import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Storage } from "aws-amplify";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Stack,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useWeb3Provider } from "../hooks/Web3Provider";

const NftMedal = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nftName: "",
      description: "",
      issuer: "",
      issueDate: "",
      link: "",
    },
  });
  const { walletAddress } = useParams();
  const navigate = useNavigate();
  const { medalMint } = useWeb3Provider();
  const [nftImageUrl, setNftImageUrl] = useState();
  const [nftFileName, setNftFileName] = useState();
  const [imageUploadErr, setImageUploadErr] = useState(false);
  const [metadataList, setMetadataList] = useState([]);
  const [metadataObj, setMetadataObj] = useState([]);
  const [metadataId, setMetadataId] = useState();
  const [isLocked, setIsLocked] = useState(false);
  const [selectedMetadataId, setSelectedMetadataId] = useState("");
  const [selectedMetadata, setSelectedMetadata] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getMetadataList = async (data) => {
    const res = await API.graphql({
      query: queries.listBiztechNftMedals,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          limit: 99999999,
        },
      },
    });
    console.log(res.data);
    const metadataList = res.data.listBiztechNftMedals.items;
    const metadataObj = Object.fromEntries(
      metadataList.map((obj) => [obj.id, obj])
    );
    setMetadataList(metadataList);
    setMetadataObj(metadataObj);
    return metadataList;
  };
  const createMetadata = async (data) => {
    const res = await API.graphql({
      query: mutations.createBiztechNftMedal,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          name: data.nftName,
          description: data.description,
          issuer: data.issuer,
          issueDate: data.issueDate,
          link: data.link,
          isLocked: isLocked,
          imageUrl: nftImageUrl,
        },
      },
    });
    console.log(res.data);
    return res.data.createBiztechNftMedal.id;
  };
  const updateMetadata = async (data) => {
    const res = await API.graphql({
      query: mutations.updateBiztechNftMedal,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id: metadataId,
          name: data.nftName,
          description: data.description,
          issuer: data.issuer,
          issueDate: data.issueDate,
          link: data.link,
          isLocked: isLocked,
          imageUrl: nftImageUrl,
        },
      },
    });
    console.log(res.data);
    return res.data.updateBiztechNftMedal.id;
  };

  const mintMedalNft = async () => {
    setIsLoading(true);
    if (selectedMetadataId === "new") {
      await medalMint(walletAddress, `${metadataId}.json`, isLocked);
      navigate(`/detail/${walletAddress}`);
    } else if (selectedMetadataId) {
      await medalMint(walletAddress, `${selectedMetadataId}.json`, isLocked);
      navigate(`/detail/${walletAddress}`);
    }
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    if (!nftFileName) {
      setImageUploadErr(true);
      return;
    } else {
      setImageUploadErr(false);
    }
    if (!metadataId) {
      const createdId = await createMetadata(data);
      setMetadataId(createdId);
    } else {
      await updateMetadata(data);
    }
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    const fileName = `image/${uuidv4()}.jpg`;
    try {
      await Storage.put(fileName, file, {
        contentType: file.type,
      });
      const imageUrl = await Storage.get(fileName);
      setNftImageUrl(imageUrl.split("?")[0]);
      setNftFileName(fileName);
      setImageUploadErr(false);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  useEffect(() => {
    getMetadataList();
  }, []);

  const validationRules = {
    nftName: {
      required: "NFTの名前を入力してください。",
    },
    description: {
      required: "説明文を入力してください。",
    },
    issuer: {
      required: "発行元を入力してください。",
    },
    issueDate: {
      required: "発行日時を入力してください。",
    },
    link: {
      required: "関連ページURLを入力してください。",
    },
  };
  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box sx={{ m: 5 }}>
          <Stack
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            spacing={2}
            // sx={{ width: 350 }}
          >
            <FormControl>
              <InputLabel id="metadata-select-label">NFTを選択</InputLabel>
              <Select
                labelId="metadata-select-label"
                id="metadata-select"
                value={selectedMetadataId}
                onChange={async (event) => {
                  setSelectedMetadataId(event.target.value);
                  if (event.target.value && event.target.value !== "new") {
                    setSelectedMetadata(metadataObj[event.target.value]);
                    setIsLocked(metadataObj[event.target.value].isLocked);
                  }
                }}
                autoWidth
                label="NFT選択"
              >
                <MenuItem value="new">新規作成</MenuItem>
                {metadataList?.map((metadata, index) => (
                  <MenuItem value={metadata.id} key={metadata.id}>
                    {metadata.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedMetadataId === "new" && (
              <>
                <Box sx={{ mx: 2 }}>
                  {nftImageUrl ? (
                    <>
                      <Box
                        component="img"
                        sx={{
                          width: 250,
                          verticalAlign: "middle",
                          mb: 2,
                        }}
                        alt="polygonLogo"
                        src={nftImageUrl}
                      />
                    </>
                  ) : (
                    <>
                      <Box>
                        <Button
                          variant="outlined"
                          component="label"
                          sx={{
                            color: "rgba(0, 0, 0, 0.50)",
                            borderColor: "rgba(0, 0, 0, 0.30)",
                            width: 250,
                            height: 250,
                            borderRadius: 1,
                            p: 1,
                            mb: 2,
                          }}
                        >
                          NFT画像のアップロード
                          <input type="file" hidden onChange={uploadFile} />
                        </Button>
                      </Box>
                    </>
                  )}

                  {imageUploadErr && (
                    <Typography sx={{ color: "red", fontSize: 10 }}>
                      画像をアップロードしてください。
                    </Typography>
                  )}
                </Box>

                <Controller
                  name="nftName"
                  control={control}
                  rules={validationRules.nftName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="NFTの名前"
                      error={errors.nftName !== undefined}
                      helperText={errors.nftName?.message}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  rules={validationRules.description}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="説明文"
                      error={errors.description !== undefined}
                      helperText={errors.description?.message}
                      multiline
                      maxRows={10}
                      minRows={3}
                    />
                  )}
                />
                <Controller
                  name="issuer"
                  control={control}
                  rules={validationRules.issuer}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="発行元"
                      error={errors.issuer !== undefined}
                      helperText={errors.issuer?.message}
                    />
                  )}
                />
                <Controller
                  name="issueDate"
                  control={control}
                  rules={validationRules.issueDate}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="発行日時"
                      error={errors.issueDate !== undefined}
                      helperText={errors.issueDate?.message}
                    />
                  )}
                />
                <Controller
                  name="link"
                  control={control}
                  rules={validationRules.link}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="関連ページURL"
                      error={errors.link !== undefined}
                      helperText={errors.link?.message}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isLocked}
                      onChange={() => setIsLocked(!isLocked)}
                    />
                  }
                  label="譲渡不可のNFTにする"
                />

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      width: 300,
                      p: 2,
                      border: 2,
                      borderRadius: 8,
                      display: "block",
                      margin: "auto",
                      my: 1,
                      "&:hover": {
                        border: 2,
                      },
                    }}
                  >
                    {metadataId ? "更新する" : "保存する"}
                  </Button>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: 300,
                      p: 2,
                      border: 2,
                      borderRadius: 8,
                      display: "block",
                      margin: "auto",
                      my: 1,
                      "&:hover": {
                        border: 2,
                      },
                    }}
                    disabled={!metadataId}
                    onClick={mintMedalNft}
                  >
                    {isLoading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "#FFF" }} />
                    ) : (
                      "NFTメダルを発行して送信する"
                    )}
                  </Button>
                </Box>
              </>
            )}
            {selectedMetadataId && selectedMetadataId !== "new" && (
              <>
                <Box
                  component="img"
                  sx={{
                    width: 250,
                    verticalAlign: "middle",
                    mb: 2,
                  }}
                  alt="NFTimage"
                  src={selectedMetadata.imageUrl}
                />

                <Controller
                  name="nftName"
                  control={control}
                  rules={validationRules.nftName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="NFTの名前"
                      error={errors.nftName !== undefined}
                      helperText={errors.nftName?.message}
                      value={selectedMetadata.name}
                      disabled
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  rules={validationRules.description}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="説明文"
                      error={errors.description !== undefined}
                      helperText={errors.description?.message}
                      multiline
                      maxRows={10}
                      minRows={3}
                      value={selectedMetadata.description}
                      disabled
                    />
                  )}
                />
                <Controller
                  name="issuer"
                  control={control}
                  rules={validationRules.issuer}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="発行元"
                      error={errors.issuer !== undefined}
                      helperText={errors.issuer?.message}
                      value={selectedMetadata.issuer}
                      disabled
                    />
                  )}
                />
                <Controller
                  name="issueDate"
                  control={control}
                  rules={validationRules.issueDate}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="発行日時"
                      error={errors.issueDate !== undefined}
                      helperText={errors.issueDate?.message}
                      value={selectedMetadata.issueDate}
                      disabled
                    />
                  )}
                />
                <Controller
                  name="link"
                  control={control}
                  rules={validationRules.link}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="関連ページURL"
                      error={errors.link !== undefined}
                      helperText={errors.link?.message}
                      value={selectedMetadata.link}
                      disabled
                    />
                  )}
                />
                <FormControlLabel
                  control={<Checkbox checked={isLocked} />}
                  label="譲渡不可のNFTにする"
                  disabled
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: 300,
                      p: 2,
                      border: 2,
                      borderRadius: 8,
                      display: "block",
                      margin: "auto",
                      my: 1,
                      "&:hover": {
                        border: 2,
                      },
                    }}
                    onClick={mintMedalNft}
                  >
                    {isLoading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "#FFF" }} />
                    ) : (
                      "NFTメダルを発行して送信する"
                    )}
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default NftMedal;
