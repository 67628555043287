import { I18n } from "aws-amplify";
import {
  Authenticator,
  translations,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../styles/auth.css";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

I18n.putVocabularies({
  ja: {
    "Account recovery requires verified contact information":
      "アカウントの復旧には確認済みの連絡先が必要です",
    "An account with the given email already exists.":
      "入力されたメールアドレスのアカウントが既に存在します",
    "Back to Sign In": "サインインに戻る",
    "Change Password": "パスワードを変える ",
    Code: "コード",
    Confirm: "確定",
    "Confirm a Code": "コードを確認",
    "Confirm Password": "パスワードの確認",
    "Confirm Sign In": "サインインする",
    "Confirm Sign Up": "登録する",
    "Confirmation Code": "確認コード",
    "Create a new account": "新しいアカウントを作成",
    "Create account": "アカウント作成",
    "Create Account": "アカウント作成",
    Email: "メールアドレス",
    "Enter your password": "パスワードを入力 ",
    "Enter your username": "ユーザー名を入力 ",
    "Forgot Password": "パスワードを忘れた ",
    "Forgot your password?": "パスワードを忘れましたか？ ",
    "Have an account? ": "アカウントを持っていますか？",
    "Incorrect username or password": "ユーザー名かパスワードが異なります ",
    "Invalid password format": "パスワードの形式が無効です ",
    "Invalid phone number format":
      "不正な電話番号の形式です。\n+12345678900 の形式で入力してください",
    "Lost your code? ": "コードを失くしましたか？",
    "New Password": "新しいパスワード",
    "No account? ": "アカウントが無いとき ",
    or: "又は",
    Password: "パスワード ",
    "Password attempts exceeded": "サインインの試行回数が上限に達しました",
    "Phone Number": "電話番号",
    "Resend Code": "コードを再送信",
    "Reset password": "パスワードをリセット ",
    "Reset your password": "パスワードをリセット",
    "Send Code": "コードを送信",
    "Send code": "コードを送信",
    "Sign in": "サインイン",
    "Sign In": "サインイン ",
    "Sign in to your account": "アカウントにサインイン ",
    "Sign In with Amazon": "Amazonでサインイン",
    "Sign In with Facebook": "Facebookでサインイン",
    "Sign In with Google": "Googleでサインイン",
    "Sign Out": "サインアウト ",
    "Sign Up": "アカウント作成",
    Skip: "スキップ",
    Submit: "送信",
    "User already exists": "既にユーザーが存在しています ",
    "User does not exist": "ユーザーが存在しません ",
    Username: "ユーザー名 ",
    "Username cannot be empty": "ユーザー名は入力必須です",
    Verify: "確認",
    "Verify Contact": "連絡先を確認",
    "We Emailed You": "メールを送信しました",
    "We Sent A Code": "認証コードを送信しました",
    "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
      "メールに記載されているコードを入力してください。メール到着まで1分ほどかかる場合があります。",
    "Your code is on the way. To log in, enter the code we emailed to":
      "確認コードを送信しました：",
    "It may take a minute to arrive.":
      "メール到着まで1分ほどかかる場合があります。",
    "Enter your code": "コード入力",
    "Your code is on the way.":
      "メールに記載されているコードを入力してください。メール到着まで1分ほどかかる場合があります。",
    "Password must have at least 8 characters":
      "パスワードは8文字以上を入力してください",
    "Password cannot be empty": "パスワードを入力してください",
    "Your passwords must match": "確認用パスワードが一致しません",
    "User does not exist.": "ユーザーが存在しません",
    "Incorrect username or password.": "ユーザー名またはパスワードが違います",
    "User is not confirmed.": "ユーザーは検証されていません",
    "User already exists": "ユーザーは既に存在します",
    "Invalid verification code provided, please try again.":
      "指定された確認コードが無効です。もう一度お試しください",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Account recovery requires verified contact information":
      "アカウントの復元には確認済みの連絡先情報が必要です",
    "Invalid phone number format":
      "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    "An account with the given email already exists.":
      "そのメールアドレスは既に存在します",
    "Username cannot be empty": "ユーザー名は必須です",
    "Password attempts exceeded": "パスワード試行回数が超過しました",
    "Attempt limit exceeded, please try after some time.":
      "試行制限を超過しました。しばらくしてからもう一度お試しください",
    "Username/client id combination not found.": "ユーザーが存在しません",
    "CUSTOM_AUTH is not enabled for the client.": "パスワードは必須です", // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
    "Password does not conform to policy: Password not long enough":
      "パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)", // 適宜修正
    "Password does not conform to policy: Password must have uppercase characters":
      "パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)", // 適宜修正
    "Password does not conform to policy: Password must have lowercase characters":
      "パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)", // 適宜修正
    "Password does not conform to policy: Password must have numeric characters":
      "パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)", // 適宜修正
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください", // 適宜修正。本来の意味とは異なるがこれで明示している。
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください", // 適宜修正。本来の意味とは異なるがこれで明示している。
    "Sign Up with Facebook": "Facebookでサインアップ",
    "Sign Up with Google": "Googleでサインアップ",
  },
});

const components = {
  SignIn: {
    Footer() {
      return <></>;
    },
  },
};

const CustomAuthenticator = () => {
  return (
    <ThemeProvider>
      <Authenticator components={components} hideSignUp></Authenticator>
    </ThemeProvider>
  );
};

export default CustomAuthenticator;
