import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Divider } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";
import ConnectButton from "./ConnectButton";
import DisconnectButton from "./DisconnectButton";
import OpenEtherscan from "./OpenEtherscan";

const WalletInfo = () => {
  const { signer, connectedWalletAddress } = useWeb3Provider();
  const navigate = useNavigate();

  const toBatchPage = () => {
    navigate(`/batch`);
  };
  const toMedalEditPage = () => {
    navigate(`/metadata/edit`);
  };

  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box sx={{ m: 5 }}>
          <Box
            sx={{
              width: "100%",
              border: 1,
              p: 3,
              borderColor: "rgba(0, 0, 0, 0.26)",
              borderRadius: 3,
              mb: 3,
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                  管理者情報
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  接続中Wallet
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {connectedWalletAddress ? connectedWalletAddress : "未接続"}
                </Box>
              </Grid>
              <Grid item xs={3}>
                {signer ? (
                  <>
                    <DisconnectButton />
                  </>
                ) : (
                  <>
                    <ConnectButton />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  会員券コントラクト
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <OpenEtherscan
                  contract={process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  メダルコントラクト
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {process.env.REACT_APP_MEDAL_NFT_CONTRACT}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <OpenEtherscan
                  contract={process.env.REACT_APP_MEDAL_NFT_CONTRACT}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  メダル一括送信
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{
                    width: 180,
                    p: 1,
                    border: 2,
                    borderRadius: 5,
                    "&:hover": {
                      border: 2,
                    },
                  }}
                  onClick={toBatchPage}
                >
                  一括送信ページへ
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  メダル編集
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{
                    width: 180,
                    p: 1,
                    border: 2,
                    borderRadius: 5,
                    "&:hover": {
                      border: 2,
                    },
                  }}
                  onClick={toMedalEditPage}
                >
                  編集ページへ
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WalletInfo;
