import React, { useState } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: GREY,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function CSVReader({ setSendWalletAddress }) {
  const { CSVReader } = useCSVReader();
  const [addressType, setAddressType] = useState("wallet");
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const [rows, setRows] = useState([]);

  const handleAddressTypeChange = (event) => {
    setAddressType(event.target.value);
  };

  const handleUploadAccepted = async (results) => {
    let walletAddresses = [];
    if (addressType === "wallet") {
      if (results && results.data) {
        // Wallet Address列を読みこみ
        const keys = results.data[0];
        const walletAddressIndex = keys.indexOf("Wallet Address");
        for (let i = 1; i < results.data.length; i++) {
          const values = results.data[i];
          walletAddresses.push(values[walletAddressIndex]);
        }
      }
    } else if (addressType === "email") {
      const emailAddresses = [];
      if (results && results.data) {
        // メールアドレス列を読みこみ
        const keys = results.data[0];
        const emailAddressIndex = keys.indexOf("メールアドレス");
        for (let i = 1; i < results.data.length; i++) {
          const values = results.data[i];
          emailAddresses.push(values[emailAddressIndex]);
        }
        // DBから顧客情報を取得
        const customerList = await fetchCustomer();

        // メールアドレスからウォレットアドレスを取得
        walletAddresses = customerList
          .filter((item) => emailAddresses.includes(item.email))
          .map((item) => item.walletAddress);
      }
    }

    const walletObjects = walletAddresses.map((address) => ({
      WalletAddress: address,
    }));

    setSendWalletAddress(walletAddresses);
    setRows(walletObjects);
    console.log(walletAddresses);
    console.log(walletAddresses.length);
    setZoneHover(false);
  };

  const fetchCustomer = async () => {
    const result = await API.graphql({
      query: queries.listBiztechCustomers,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        limit: 999999999,
        visitsLimit: 999999999,
      },
    });
    console.log(result.data);
    const rows = result.data.listBiztechCustomers.items.map((item) => {
      return {
        walletAddress: item.walletAddress,
        email: item.email,
      };
    });
    return rows;
  };

  return (
    <>
      <Typography sx={{ fontSize: 18 }}>送信先設定</Typography>
      <FormControl component="fieldset">
        <FormLabel>CSVから読み込む列</FormLabel>
        <RadioGroup
          aria-label="addressType"
          name="addressType"
          value={addressType}
          onChange={handleAddressTypeChange}
        >
          <FormControlLabel
            value="wallet"
            control={<Radio />}
            label="Wallet Address"
          />
          <FormControlLabel
            value="email"
            control={<Radio />}
            label="メールアドレス"
          />
        </RadioGroup>
      </FormControl>
      <CSVReader
        onUploadAccepted={handleUploadAccepted}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                "CSVファイルアップロード"
              )}
            </div>
          </>
        )}
      </CSVReader>
      {rows.length > 0 && (
        <>
          <Typography>{rows.length}件 読み込まれました</Typography>
          <DataGrid
            columns={[
              {
                field: "WalletAddress",
                headerName: "Wallet Address",
                minWidth: 100,
                flex: 1,
              },
            ]}
            rows={rows}
            autoHeight
            components={{
              Toolbar: GridToolbar,
            }}
            getRowId={(row) => row.WalletAddress}
          />
        </>
      )}
    </>
  );
}
