import { Box, Grid, Divider } from "@mui/material";

const MedalNft = ({ medalNft }) => {
  return (
    <>
      <Grid item xs={4}>
        <Box
          component="img"
          sx={{
            textAlign: "center",
            width: 120,
            display: "block",
            margin: "auto",
          }}
          src={medalNft.metadata.image}
        />
      </Grid>
    </>
  );
};

export default MedalNft;
