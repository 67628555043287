import BatchNftMedal from "../components/BatchNftMedal";

const BatchMintMedal = () => {
  return (
    <>
      <BatchNftMedal />
    </>
  );
};

export default BatchMintMedal;
