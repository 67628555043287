/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:e91a84ff-4f74-4d24-a927-c9622c0c7af5",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_gSI8KZz3t",
    "aws_user_pools_web_client_id": "6rha1a9r4frfq38m5jdk83iveo",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://qxom2wxkfrhwhifrrjw53o55za.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "biztechAdminRestApi",
            "endpoint": "https://8h4ixl3bp4.execute-api.ap-northeast-1.amazonaws.com/prd",
            "region": "ap-northeast-1"
        },
        {
            "name": "biztechMetadataApi",
            "endpoint": "https://5knm3zoxxg.execute-api.ap-northeast-1.amazonaws.com/prd",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "biztech134405-prd",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
