import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";

const DisconnectButton = () => {
  const { disconnect } = useWeb3Provider();
  const disconnectHandler = async () => {
    await disconnect();
  };
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: "red",
          borderColor: "red",
          "&:hover": {
            borderColor: "red",
            border: 2,
          },
          width: 180,
          p: 1,
          border: 2,
          borderRadius: 5,
        }}
        onClick={disconnectHandler}
      >
        接続解除する
      </Button>
    </>
  );
};

export default DisconnectButton;
